// ============================================================================
// UserProfileService
// ------------------
// UserProfile module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import API from '@/apis/UserProfileApi'

import { Bus as AppEventBus, Events as AppEvents } from '@/events/AppEvents'

// -------
// Exports
// -------
export default {
	updateUserInfo: function (data) {
		return API.updateUserInfo(data).then(userData => {
			store.dispatch('user/setUser', userData)
			AppEventBus.emit(AppEvents.USER_UPDATED, userData)
			return userData
		})
	},
	getUserSettings: function () {
		return API.getUserSettings().then(res => res.data.data)
	},
	updateUserSettings: function (data) {
		return API.updateUserSettings({ settings: data })
	}
}
