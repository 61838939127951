// ============================================================================
// UserProfileApi
// --------------
// User profile api
// ============================================================================

// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

// -------
// Exports
// -------
export default {
	getUserSettings: function () {
		return Backend.GET(URLS.api.userSettings.uri, [])
	},
	updateUserInfo: function (data) {
		return Backend.PATCH(URLS.api.me.uri, [], null, data)
	},
	updateUserSettings: function (data) {
		return Backend.POST(URLS.api.userSettings.uri, [], null, data)
	}
}
