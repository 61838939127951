<template>
  <v-list>
    <v-list-tile v-for="(section, index) in sections" :key="index" avatar @click="goTo(section.routeName)">
      <v-list-tile-avatar>
        <v-icon :color="section.routeName === currentRouteName ? 'primary' : ''">{{ section.icon }}</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title :class="section.routeName === currentRouteName ? 'primary--text' : ''">{{ section.text }}</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
  </v-list>
</template>

<script>
import UserProfileModuleGuard from '@/mixins/ModulesGuards/UserProfile/UserProfileModuleGuard'

export default {
	name: 'ProfileSettings',
	mixins: [UserProfileModuleGuard],
	computed: {
		currentRouteName: function () {
			return this.$route.name
		},
		sections: function () {
			let result = [
				{
					icon: 'account_circle',
					routeName: 'profile-user',
					text: this.$t('profile.sidebar.profile')
				},
				{
					icon: 'lock',
					routeName: 'profile-password',
					text: this.$t('profile.sidebar.password')
				}
			]
			if (this.appService.hasModule('document-edition') || this.appService.hasModule('webdav')) {
				result.push({
					icon: 'folder',
					routeName: 'profile-ecm-preferences',
					text: this.$t('profile.sidebar.ecm')
				})
			}
			if (this.appService.hasModule('notification')) {
				result.push({
					icon: 'notifications_none',
					routeName: 'profile-notifications',
					text: this.$t('profile.sidebar.notifications')
				})
			}

			result.push({
				icon: 'info',
				routeName: 'terms-of-service',
				text: this.$t('cgu.general_terms_and_conditions')
			})

			return result
		}
	},
	methods: {
		goTo: function (route) {
			return this.appService.goTo(route)
		}
	}
}
</script>
